import React, { useState, useEffect } from "react";
import Head from "next/head";
import Router from "next/router";
import useAuth from "~/hooks/useAuth";
import HeaderSlim from "~/components/HeaderSlim";
import { Container } from "react-bootstrap";
import createForm from "~/shared/Form";
import TextInput from "~/shared/TextInput";
import PasswordInput from "~/shared/PasswordInput";
import Button from "~/shared/Btn";
import Title from "~/shared/Title";
import Spinner from "~/shared/Spinner";
import yup from "~/utils/yup";
import { FormContainer, Content } from "~/styles/confort/login/style";
import PassWordModal from "./PassWordModal";

import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-utilities.min.css";

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required("*campo obrigatório"),
});

const [Field, Form] = createForm(
    {
        email: {
            component: TextInput,
            placeholder: "E-mail",
        },
        password: {
            component: PasswordInput,
            placeholder: "Senha",
        },
    },
    { validationSchema: schema }
);

const Login = () => {
    const [hasError, setHasError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { user, login, loading, isAuthenticated, account } = useAuth();

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const doLogin = async () => {
        setIsLoading(true);
        setHasError(false);
        if (email.length && password.length) {
            try {
                const validFields = await schema.isValid({ email, password }, { abortEarly: false });

                if (!validFields) {
                    setIsLoading(false);
                    setHasError(true);
                    return;
                }

                const [statusCode, account] = await login(email, password, "30d");

                const { to } = Router.query;

                if (statusCode === 200) {

                    if(account?.organizationRole === 'correio') {
                        Router.push('/relatorio-vendas/aguardando')
                        return;
                    }

                    const dashPage = !account?.organization?.id ? "/marketplace/dashboard" : "/dashboard";
                    Router.push(to && !to.includes("/login") ? to : dashPage);
                    return;
                }
            } catch (err) {
                setHasError(true);
                setIsLoading(false);
                setPassword("");
                return err;
            }
        } else {
            setHasError(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        console.log({user, account})
        if (user && account.organizationRole !== "correio") {
            Router.push("/dashboard");
            return;
        }else if(user && account.organizationRole === "correio") {
            Router.push('/relatorio-vendas/aguardando')
        }
    }, [loading]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const redirect = params.get("to");

        if (redirect) {
            localStorage.setItem("redirect", redirect);
        }
    }, []);

    return !loading && !isAuthenticated ? (
        <>
            <Head>
                <title> Login - Fotop Serviços</title>
            </Head>
            <HeaderSlim />
            <Container>
                <PassWordModal show={showModal} onHide={handleClose} />
                <Content>
                    <Title level="1">Realize seu login para avançar</Title>

                    <FormContainer>
                        <div>
                            <TextInput
                                name="email"
                                value={email}
                                placeholder="E-mail"
                                onChange={(_, value) => setEmail(value)}
                            />
                            <PasswordInput
                                name="password"
                                value={password}
                                placeholder="Senha"
                                onChange={(_, value) => setPassword(value)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        doLogin();
                                    }
                                }}
                            />
                            {hasError ? <p>*E-mail ou senha inválidos</p> : ""}
                            <div style={{ marginTop: "1rem" }}>
                                <Button block onClick={doLogin}>
                                    {isLoading ? <Spinner /> : "Acessar"}
                                </Button>
                            </div>
                            <div className="forgot-button">
                                <button type="button" onClick={handleShow}>
                                    Esqueci minha senha
                                </button>
                            </div>
                        </div>
                    </FormContainer>
                    <div className="separete-bar">
                        <div className="bar" />
                        <p>ou</p>
                        <div className="bar" />
                    </div>
                    <Button onClick={() => Router.push("/register")} block type="submit">
                        Ainda não sou cliente
                    </Button>
                </Content>
            </Container>
        </>
    ) : null;
};

export default Login;
